import { render, staticRenderFns } from "./CreateAndEditBag.vue?vue&type=template&id=d3d2c362&scoped=true"
import script from "./CreateAndEditBag.vue?vue&type=script&lang=js"
export * from "./CreateAndEditBag.vue?vue&type=script&lang=js"
import style0 from "./CreateAndEditBag.vue?vue&type=style&index=0&id=d3d2c362&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3d2c362",
  null
  
)

export default component.exports